<template>
  <div>
    <div
      class="carousel is-loading has-text-centered"
      :class="{ 'is-large': isLarge }"
      key="loading"
      v-if="loading"
    >
      <button class="button is-white is-loading is-medium" />
    </div>
    <div class="carousel has-background-white-bis" :class="{ 'is-large': isLarge }" key="carousel" v-else>
      <div class="carousel-items">
        <div class="control">
          <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div class="tags has-addons has-text-weight-bold" v-if="overlay">
              <span
                class="tag is-rounded is-danger is-light is-uppercase"
                v-if="isStockImage"
              >Stock image</span>
              <span class="tag is-rounded is-dark" v-else-if="hasPhotos">
                <span class="icon is-small">
                  <component
                    :is="faVersion === 'css' ? 'i' : 'fa'"
                    :icon="['fal', 'camera']"
                    class="fal fa-camera"
                  />
                </span>
                <span>{{ currentPosition }}</span>
              </span>
              <span class="tag is-rounded has-background-grey-lighter is-light is-uppercase" v-else>No image</span>
            </div>
          </transition>
        </div>
        <div class="control">
          <a
            class="icon is-medium has-text-white"
            @click="toggleOverlay"
            :title="overlay ? 'Hide icons' : 'Show icons'"
            v-tippy="{ placement: 'left' }"
          >
            <component
              :is="faVersion === 'css' ? 'i' : 'fa'"
              class="fa-2x"
              :icon="['fal', overlay ? 'eye' : 'eye-slash']"
              :class="[overlay ? 'fal fa-eye' : 'fal fa-eye-slash']"
            />
          </a>
        </div>
      </div>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div v-if="hasPhotos" key="photos">
          <div v-for="(photo, index) in images" :key="index">
            <div v-if="index === slide" class="is-overlay" :style="getActiveSlideClass(photo)" />
          </div>
        </div>
        <div v-else key="empty" class="is-overlay" :style="emptyClass" />
      </transition>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div v-if="hasPhotos && hasMultiple" class="carousel-items">
          <a class="has-text-white" @click="previous">
            <span class="icon is-medium">
              <component
                :is="faVersion === 'css' ? 'i' : 'fa'"
                class="fa-2x fal fa-arrow-left"
                :icon="['fal', 'arrow-left']"
              />
            </span>
          </a>
          <a class="has-text-white" @click="next">
            <span class="icon is-medium">
              <component
                :is="faVersion === 'css' ? 'i' : 'fa'"
                class="fa-2x fal fa-arrow-right"
                :icon="['fal', 'arrow-right']"
              />
            </span>
          </a>
        </div>
      </transition>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div class="carousel-items is-flex-end">
          <div>
            <div v-if="overlay" class="field">
              <span class="has-background-white has-text-weight-semibold has-shadow has-text-dark tag" v-clipboard:copy="cid" v-if="cid">
                <span class="heading is-marginless">
                  <span class="has-text-weight-bold">CID</span>&nbsp;
                  <span>{{ cid }}</span>
                </span>
              </span>
            </div>
            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
              <div v-if="overlay" class="field">
                <VrmTag
                  v-if="vrm"
                  v-bind="{ vrm }"
                />
              </div>
            </transition>
          </div>
          <button
            v-if="overlay && showCheck && (hasAutoCheck || hasMotorCheck)"
            class="button is-info is-circular is-image-tick"
            :class="{ 'is-loading': precheck }"
            title="Check this vehicle"
            v-tippy
            :disabled="precheck"
            @click="$emit('check')"
          >
            <span class="icon">
              <span v-if="hasMotorCheck" class="has-text-weight-bold">MC</span>
              <component
                v-else
                :is="faVersion === 'css' ? 'i' : 'fa'"
                :icon="['fal', 'check']"
                class="fal fa-check"
              />
            </span>
          </button>
          <div
            v-if="status && $mq !== 'mobile'"
            class="tag is-medium has-text-weight-bold"
            :class="`is-${status.class}`"
          >{{ status.text }}</div>
          <div
            v-else-if="status"
            class="tag is-medium has-text-weight-bold"
            :class="`is-${status.class}`"
          >
            <span class="icon">
              <i class="fas" :class="autoCheckStatusIcon(status.text)" />
            </span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
export default {
  name: 'VehicleCarousel',
  data: () => ({
    slide: 0,
    overlay: true
  }),
  props: {
    faVersion: {
      type: String,
      required: false,
      default: () => 'css'
    },
    showCheck: {
      type: Boolean,
      default: () => false
    },
    photos: {
      type: Array,
      required: false,
      default: () => []
    },
    placeholderUrl: {
      type: String,
      required: false,
      default: () => ''
    },
    vrm: {
      type: String,
      required: false,
      default: () => null
    },
    vehicleId: {
      type: Number,
      required: false,
      default: () => 0
    },
    cid: {
      type: [String, Number],
      required: false,
      default: () => null
    },
    status: {
      type: Object,
      required: false,
      default: () => null
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasAutoCheck: {
      type: Number,
      required: false,
      default: () => 0
    },
    hasMotorCheck: {
      type: Number,
      required: false,
      default: () => 0
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    precheck: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    VrmTag: () => import('../../../components/VrmTag')
  },
  computed: {
    images() {
      return this.photos && this.photos.length > 0 ? this.photos : []
    },
    hasPhotos() {
      return this.images && this.images.length
    },
    hasMultiple() {
      return this.images && this.images.length > 1
    },
    isStockImage() {
      return this.images.filter((p) => p.includes('dm70s224d49i4')).length
    },
    currentPosition() {
      return `${this.slide + 1} of ${this.images.length}`
    },
    emptyClass() {
      const { placeholderUrl } = this
      if (placeholderUrl) return {
        background: `url('${placeholderUrl}') rgb(251, 251, 251)`,
        backgroundSize: 'cover',
        backgroundPosition:  '10%',
        maxWidth:  '100vw'
      } 
      else return {
        background: 'url(' + require('../../../img/logos/percayso-logo.svg') + ') center center no-repeat' ,
        margin: this.$mq === 'mobile' ? '3.5em auto' : '0.75em auto',
        maxWidth: '70%'
      }
    },
  },
  methods: {
    autoCheckStatusIcon(status) {
      if (status === 'Warning') {
        return 'fa-exclamation-circle'
      } else if (status === 'Caution') {
        return 'fa-question-circle'
      } else {
        return 'fa-check-circle'
      }
    },
    toggleOverlay() {
      const action = this.overlay ? 'hide' : 'show'
      this.$emit('track', `${action}_carousel_icons`)
      this.overlay = !this.overlay
    },
    getActiveSlideClass(photo) {
      return {
        background: `url('${
          this.images && this.images.length && photo
            ? photo
            : '${this.placeholderUrl}'
        }') #f1f1f1`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        maxWidth: '100vw'
      }
    },
    next() {
      this.slide === this.images.length - 1
        ? (this.slide = 0)
        : (this.slide += 1)
    },
    previous() {
      this.slide === 0
        ? (this.slide = this.images.length - 1)
        : (this.slide -= 1)
    }
  }
}
</script>

<style lang="sass" scoped>
.carousel
  position: relative
  height: 15rem
  border-radius: 4px
  max-width: 100vw
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 0.75rem
  margin-bottom: 0.75rem
  @media screen and (max-width: 768px)
    height: 12rem
  &.is-large
    height: 24rem
  &.is-loading
    justify-content: center
  .carousel-items
    display: flex
    justify-content: space-between
    z-index: 1
    &.is-flex-end
      align-items: flex-end
      min-height: 5.25rem
.is-overlay
  border-radius: 4px
</style>
